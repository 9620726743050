import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'favorite_circle_fill_yellow_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="favorite_circle_fill_yellow_20"><linearGradient id="favorite_circle_fill_yellow_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#ffb73d" /><stop offset="1" stop-color="#ffa000" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#favorite_circle_fill_yellow_20_a)" /><path d="M11.805 7.566l2.39.235c.831.081 1.086.912.444 1.458l-1.87 1.591.694 2.592c.228.851-.48 1.366-1.189.854l-2.273-1.643-2.273 1.643c-.706.51-1.417-.003-1.188-.854l.694-2.592-1.87-1.59c-.646-.549-.392-1.377.443-1.46l2.39-.234 1.052-2.483c.33-.778 1.174-.777 1.504 0z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20FavoriteCircleFillYellowProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20FavoriteCircleFillYellow: FC<Icon20FavoriteCircleFillYellowProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20FavoriteCircleFillYellow as any).mountIcon = mountIcon;

export default Icon20FavoriteCircleFillYellow;
